import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const shifts = [
  'None',  // Added None option
  'Shift 1 (6 AM - 2 PM)',
  'Shift 2 (2 PM - 10 PM)',
  'Shift 3 (10 PM - 6 AM)'
];

const ShiftTimeDropdown = ({ selectedShift, handleShiftChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="shift-label">Select Shift</InputLabel>
      <Select
        labelId="shift-label"
        id="shift-select"
        value={selectedShift}
        label="Select Shift"
        onChange={(e) => handleShiftChange(e.target.value)}
        sx={{
          width: '300px',  
          padding: '0 12px',  
          height: '35px',  
          lineHeight: '35px',  
          minHeight: '35px',  
          display: 'flex',
          alignItems: 'center',
        }}
        size="small"
      >
        {shifts.map((shift, index) => (
          <MenuItem key={index} value={shift}>
            {shift}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ShiftTimeDropdown;