
import React from 'react';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

const TimeSlotSelector = ({ startTime, endTime, onStartChange, onEndChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={styles.container}>
      
        <div style={styles.timePickerWrapper}>
          <div style={styles.timePickerItem}>
            <label style={styles.label}>Start Time:</label>
            <TimePicker
              value={startTime}
              onChange={onStartChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>

          <div style={styles.timePickerItem}>
            <label style={styles.label}>End Time:</label>
            <TimePicker
              value={endTime}
              onChange={onEndChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

const styles = {
  container: {
    padding: '20px',
  },
  timePickerWrapper: {
    display: 'flex',
    gap: '20px', 
  },
  timePickerItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
  },
};

export default TimeSlotSelector;
