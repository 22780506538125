import { useRoutes } from 'react-router-dom';
import Login from './pages/dashboard/Login';
import DashboardLayout from './layouts/DashboardLayout';
//import AssessQuality from './pages/dashboard/AssessQuality';
import Monitor from './pages/dashboard/Monitor';
import { useSelector } from 'react-redux';

export default function Router() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); 
  
    return useRoutes([
      {
        path: '/',
        element: <DashboardLayout />, 
        children: [
          {
            path: '/', // Root route
            element: isAuthenticated ? <Monitor /> : <Login />, 
          },
          {
            path: 'login', // Explicit login route
            element: <Login />, 
          },
        ],
      },
      {
        path: '*',
        element: <Login />, // Catch-all for undefined routes
      },
    ]);
}
  
  
  
  
  
  
  
