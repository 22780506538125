// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { FormControl, InputLabel, Select, MenuItem, CircularProgress, FormHelperText } from '@mui/material';

// const MONITOR_POST_URL = `http://localhost:8000/suppliers`;

// const SupplierDropdown = ({ handleSupplierChange }) => {
//   const [suppliers, setSuppliers] = useState([]);
//   const [selectedSupplier, setSelectedSupplier] = useState('');
//   const [loading, setLoading] = useState(true); // New loading state
//   const [error, setError] = useState(null); // New error state
//   const customerId = useSelector((state) => state.auth.id);  // Ensure the correct field from your auth slice

//   useEffect(() => {
//     if (customerId) {
//       setLoading(true); // Start loading
//       setError(null); // Reset error state
//       fetch(MONITOR_POST_URL, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ customerId }),  // Send customerId in the request body
//       })
//         .then((res) => {
//           if (!res.ok) {
//             throw new Error('Network response was not ok'); // Handle non-2xx responses
//           }
//           return res.json();
//         })
//         .then((data) => {
//           setSuppliers(data); // Set suppliers data
//           setSelectedSupplier(''); // Reset selected supplier
//         })
//         .catch((error) => {
//           console.error('Error fetching suppliers:', error);
//           setError('Failed to load suppliers. Please try again.'); // Set error message
//         })
//         .finally(() => {
//           setLoading(false); // Stop loading
//         });
//     }
//   }, [customerId]);

//   const handleSupplierSelectChange = (event) => {
//     const value = event.target.value;
//     setSelectedSupplier(value);
//     handleSupplierChange(value);  // Call the parent function with the selected value
//   };

//   return (
//     <FormControl fullWidth error={!!error}>
//       <InputLabel id="supplier-label">Select Supplier</InputLabel>
//       {loading ? ( // Show loading spinner while fetching data
//         <CircularProgress size={24} />
//       ) : (
//         <Select
//           labelId="supplier-label"
//           id="supplier-select"
//           value={selectedSupplier}
//           onChange={handleSupplierSelectChange}
//           label="Select Supplier"
//           sx={{
//             width: '300px',  
//             padding: '0 12px',  
//             height: '35px',  
//             lineHeight: '35px',  
//             minHeight: '35px',  
//             display: 'flex',
//             alignItems: 'center',
//           }}
//           size="small"
//         >
//           <MenuItem value="None">None</MenuItem>
//           {suppliers.map((supplier) => (
//             <MenuItem key={supplier.id} value={supplier.sup_name}>
//               {supplier.sup_name}
//             </MenuItem>
//           ))}
//         </Select>
//       )}
//       {error && <FormHelperText>{error}</FormHelperText>} {/* Show error message if any */}
//     </FormControl>
//   );
// };

// export default SupplierDropdown;
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SupplierDropdown = ({ selectedSupplier, handleSupplierChange, suppliers }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="supplier-label">Select Supplier</InputLabel>
      <Select
        labelId="supplier-label"
        id="supplier-select"
        value={selectedSupplier || 'None'}  // Default to 'None' if no supplier is selected
        label="Select Supplier"
        onChange={(e) => handleSupplierChange(e.target.value)}
        sx={{
          width: '300px',
          padding: '0 12px',
          height: '35px',
          lineHeight: '35px',
          minHeight: '35px',
          display: 'flex',
          alignItems: 'center',
          borderColor: 'green',
        }}
        size="small"
      >
        <MenuItem value="None">None</MenuItem> {/* Added None option */}
        {suppliers && suppliers.length > 0 ? (
          suppliers.map((supplier) => (
            <MenuItem key={supplier.id} value={supplier.sup_name}>
              {supplier.sup_name} {/* Changed from 'name' to 'sup_name' */}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="None">No suppliers available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SupplierDropdown;