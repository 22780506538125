import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import axios from 'axios';

const SUPPLIER_POST_URL=`${process.env.REACT_APP_ECO_BASE_URL}/suppliers`;
export const submitSupplierData = createAsyncThunk('suppliers/UpdatePredictionWithSupplier', async (body, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(SUPPLIER_POST_URL, body);

            if (!response.status === 200) {
                throw new Error('Network response was not ok');
            }

            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const supplierSlice = createSlice({
    name: 'supplier',
    initialState: {
        supplierData: null,
        loading: false,
        error: null,
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitSupplierData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitSupplierData.fulfilled, (state, action) => {
                state.loading = false;
                state.supplierData = action.payload; 
            })
            .addCase(submitSupplierData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload; 
            });
    },
});

export default supplierSlice.reducer;
